/* istanbul ignore file */
/* eslint-disable */
export const environment: {
kushkiUrl: string;
envName: string;
customerRoles: string;
riskRoles: string;
devTools: boolean;
firebaseConfig: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
};
} = {
customerRoles: "BackofficeMasterCustomer,ChargebackManager",
riskRoles: "RiskAnalyst,RiskManager,Console.Transaction.Read",
kushkiUrl: "https://api.kushkipagos.com",
envName: "primary",
devTools: false,
firebaseConfig: {
    apiKey:"AIzaSyAXtQlq6sXC3-cVMCUvwp6DUC1RteuT4VA",
    authDomain:"kushki-97eba.firebaseapp.com",
    databaseURL: "https://kushki-97eba.firebaseio.com",
    projectId: "kushki-97eba",
    storageBucket: "kushki-97eba.appspot.com",
    messagingSenderId: "821114948879"
},
};
